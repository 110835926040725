/*
* @subsection   RD Navbar Toggles
*
* @description  Describes style declarations for RD Navbar panel
*/

.rd-navbar-static{
  .rd-navbar-panel{
    @include clearfix;
    padding: 20px 0;
    position: relative;
    .featured-news {
      position: absolute;
      right: 110px;
      top: 50%;
      transform: translateY(-50%);
      width: 320px;
      height: 70px;
      background: #f8f9fa;
      padding: 12px;
      overflow: hidden;
      border-right: 1px solid #e6e6e6;
      a {
        display: flex;
        color: $black;
        figure {
          height: 50px;
          margin-top: auto;
          margin-bottom: auto;
          background-size: cover;
          background-position: center center;
          border-radius: 2px;
          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: 86px;
        }
        p {
          margin-top: auto;
          margin-bottom: auto;
          text-align: left;
          padding-left: 10px;
          font-size: .94em;
          @include ellips(2);
          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: 210px;
        }
      }
      img {
        width: 82px;
        height: 46px;
        background: #f6f6f6;
      }
    }
  }
}

.rd-navbar-fixed{
  .rd-navbar-panel-canvas{
    position: fixed;
    height: 70px;
    left: 0;
    top: 0;
    width: 100%;
    background: $rd-navbar-white-color;
    z-index: 14;
  }

  .rd-navbar-toggle{
    position: fixed;
    z-index: 17;
    top: 8px;
    left: 4px;
  }

  .rd-navbar-panel {
    .featured-news {
      position: static;
      width: 100%;
      height: 70px;
      background: #fff;
      padding: 12px;
      overflow: hidden;
      border-left: 3px solid #e6e6e6;
      margin-top: 80px;
      margin-bottom: 14px;
      a {
        display: flex;
        color: $black;
        figure {
          height: 50px;
          margin-top: auto;
          margin-bottom: auto;
          background-size: cover;
          background-position: center center;
          border-radius: 2px;
          flex-grow: 0;
          flex-shrink: 0;
          flex-basis: 86px;
        }
        p {
          margin-top: auto;
          margin-bottom: auto;
          text-align: left;
          padding-left: 10px;
          font-size: .875em;
          @include ellips(2);
        }
      }
    }
  }
}
