/*
* @subsection   RD Navbar Search
*
* @description  Describes style declarations for RD Navbar search
*/

.rd-navbar-search,
.rd-navbar-search-toggle{
  display: none;
}

.rd-navbar-static{
  .rd-navbar-search{
    display: block;

    form{
      position: relative;
      input{
        display: block;
        line-height: 24px;
        padding: 12px 56px 12px 16px;
        height: 36px;
        color: $gray-700;
        background: #ffffff;
        border: none;
        border-radius: 2px;
        max-width: 210px;
      }

      button[type="submit"]{
        position: absolute;
          font-size: 14px;
          width: 34px;
          line-height: 24px;
          text-align: center;
          top: 0;
          height: 100%;
          right: 0;
          border: none;
          color: #6c757d;
          background: #f5f6f7;
          border-radius: 2px;
      }
    }
  }
}

.rd-navbar-fixed{
  .rd-navbar-search-toggle{
    display: block;
    position: fixed;
    top: 7px;
    right: 42px;
    font-family: FontAwesome;
    width: 48px;
    height: 48px;
    z-index: 18;
    background: 0 0;
    border: none;
    font-size: 1.25em;
    @include toggle-icons-via-rotation(48px, 28px, $rd-navbar-contrast-color, '\f078', '\f077');
  }

  .rd-navbar-search{
    display: block;

    .form-group{
      position: fixed;
      top: 0;
      padding-top: 10px;
      left: 56px;
      right: 120px;
      height: 52px;
      background: #f1f1f1;
      z-index: 17;
      @extend %rd-navbar-transition;
      visibility: hidden;
      opacity: 0;

      input{
        width: 90%;
        height: 50px;
        display: block;
        font-size: 1rem;
        line-height: 46px;
        padding-right: 34px;
        padding-left: 14px;
        border: 1px solid #dee2e6;
      }
    }

    &.active{
      .form-group{
        opacity: 1;
        visibility: visible;
      }
    }

    form{
      button[type="submit"]{
        position: absolute;
        right: 30px;
        top: 40%;
        background: transparent;
        border: none;
        font-size: 1.25em;
      }
    }
  }
}
