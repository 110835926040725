@media(min-width: 375px) {
  .rd-navbar-fixed .navbar-search input {
    width: 220px;
  }
}

@media(min-width: 414px) {
  .rd-navbar-fixed .navbar-search input {
    width: 240px;
  }
}

@media(min-width:768px) {
  .rd-navbar-fixed .navbar-search input {
    left: auto;
    right: 10rem;
  }

  .guide-buttons {
    .guide-button {
      width: 45%;
    }
  }
}

@media(min-width:1024px) {

  .submenu-list {
    display: -webkit-box;
    display: flex;
    .submenu-list-item {
        width: 25%;
        margin-bottom: 0;
        margin-right: 10px;
        &:last-child {
          margin-right: 0;
        }
      }
    }

    .navbar-search {
      right: 30px!important;
    }

    .rd-navbar-fixed .navbar-search input {
      left: auto;
      right: 7rem;
    }
}

@media(min-width:1025px) {
  .languages {
    display: none!important;
  }

  .partners {
    display: -webkit-box;
    display: flex;
  }
}



@media(min-width:1280px) {
  body {
    margin-top: 0;
  }

  .submenu-list {
    .submenu-list-item {
      .text-wrapper {
        margin-left: 60px;
      }
    }
  }

  h2 {
    &:before {
      content: "\f0c8";
      font-family: FontAwesome;
      color: #0db2ac;
      margin-right: .5rem;
      display: inline-block;
      transform: scale(.625);
    }
  }

  footer {
    padding: 4rem 0;
  }

  .sidebar {
    padding: 0 1.4rem;
  }

  .topbar {
    display: block;
  }

}


@media(max-width:768px) {
  .sidebar {
    position: fixed;
    right: 0;
    max-width: 300px;
    bottom: 0;
    top: 70px;
    background: #ffffff;
    padding: 2rem 1rem;
    border-left: 1px solid #d0d0d0;
    transform: translateX(300px);
    transition: .25s;
    &.opened {
      transform: translateX(0);
    }
  }
}
