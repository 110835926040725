@mixin ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
}

@mixin transform($trfm) {
  -webkit-transform: $trfm;
  transform: $trfm;
}

@mixin ellips($rows) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $rows;
  -webkit-box-orient: vertical;
}

@mixin transitionfb() {
  transition: 200ms cubic-bezier(.08,.52,.52,1) background-color, 200ms cubic-bezier(.08,.52,.52,1) box-shadow, 200ms cubic-bezier(.08,.52,.52,1) transform;
  transition-property: background-color, box-shadow, transform;
  transition-duration: 200ms, 200ms, 200ms;
  transition-timing-function: cubic-bezier(0.08, 0.52, 0.52, 1), cubic-bezier(0.08, 0.52, 0.52, 1), cubic-bezier(0.08, 0.52, 0.52, 1);
  transition-delay: 0s, 0s, 0s;
}
