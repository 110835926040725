@font-face {
  font-family: "DIN Pro";
  src: url("../../../fonts/din-pro-regular/15a478cc0eb1d84f4077a2a9d28df0b2.eot");
  /* IE9*/
  src: url("../../../fonts/din-pro-regular/15a478cc0eb1d84f4077a2a9d28df0b2.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("../../../fonts/din-pro-regular/15a478cc0eb1d84f4077a2a9d28df0b2.woff2") format("woff2"),
    /* chrome、firefox */
    url("../../../fonts/din-pro-regular/15a478cc0eb1d84f4077a2a9d28df0b2.woff") format("woff"),
    /* chrome、firefox */
    url("../../../fonts/din-pro-regular/15a478cc0eb1d84f4077a2a9d28df0b2.ttf") format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("../../../fonts/din-pro-regular/15a478cc0eb1d84f4077a2a9d28df0b2.svg#DIN Pro") format("svg");
  /* iOS 4.1- */
  font-display: swap;
}

@font-face {
  font-family: "DIN Pro Medium";
  src: url("../../../fonts/din-pro-medium/5fd82f3bb8dcfc3ce611fab541b92241.eot");
  /* IE9*/
  src: url("../../../fonts/din-pro-medium/5fd82f3bb8dcfc3ce611fab541b92241.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("../../../fonts/din-pro-medium/5fd82f3bb8dcfc3ce611fab541b92241.woff2") format("woff2"),
    /* chrome、firefox */
    url("../../../fonts/din-pro-medium/5fd82f3bb8dcfc3ce611fab541b92241.woff") format("woff"),
    /* chrome、firefox */
    url("../../../fonts/din-pro-medium/5fd82f3bb8dcfc3ce611fab541b92241.ttf") format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("../../../fonts/din-pro-medium/5fd82f3bb8dcfc3ce611fab541b92241.svg#DIN Pro Medium") format("svg");
  /* iOS 4.1- */
  font-display: swap;
}

@font-face {
  font-family: "DIN Pro Bold";
  src: url("../../../fonts/din-pro-bold/03f16d9e32dbe7da6c831a87bd666b3e.eot");
  /* IE9*/
  src: url("../../../fonts/din-pro-bold/03f16d9e32dbe7da6c831a87bd666b3e.eot?#iefix") format("embedded-opentype"),
    /* IE6-IE8 */
    url("../../../fonts/din-pro-bold/03f16d9e32dbe7da6c831a87bd666b3e.woff2") format("woff2"),
    /* chrome、firefox */
    url("../../../fonts/din-pro-bold/03f16d9e32dbe7da6c831a87bd666b3e.woff") format("woff"),
    /* chrome、firefox */
    url("../../../fonts/din-pro-bold/03f16d9e32dbe7da6c831a87bd666b3e.ttf") format("truetype"),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
    url("../../../fonts/din-pro-bold/03f16d9e32dbe7da6c831a87bd666b3e.svg#DIN Pro Bold") format("svg");
  /* iOS 4.1- */
  font-display: swap;
}

html {
  font-size: 14px;
  scroll-behavior: smooth;
}

body {
  font-family: 'DIN Pro';
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
  background: $white;
  scroll-behavior: smooth;
  margin-top: 60px;
}


main {
  padding-top: 2rem;
}

h1,h2,h3,h4,h5,h6 {
  position: relative;
}

h1 {
  font-size: 1.5em;
  padding-bottom: .5rem;
  margin-bottom: 2rem;
  border-bottom: 1px solid #dedede;
}

h2 {
  font-size: 1.25em;
  color: #4c4c4c;
}

h3 {
  font-size: 1.15em;
  color: #4c4c4c;
}

h4 {
  font-size: 1.1em;
  color: #4c4c4c;
}

h5 {
  font-size: 1.05em;
  color: #4c4c4c;
}

h6 {
  font-size: 1em;
  color: #4c4c4c;
}

p {
  margin-bottom: 1.5rem;
}

article {
  font-size: 1.05em;
  img {
    margin-bottom: 2rem;
  }
}


@-webkit-keyframes flash {
  0% {
    opacity: 1;
  }

  50% {
    opacity: .5;
  }

  100% {
    opacity: 1;
  }
}

@keyframes flash {
  0% {
    opacity: 1;
  }

  50% {
    opacity: .5;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes run {
  0% {
    right: 1.4rem;
    opacity: 1;
  }

  50% {
    right: .5rem;
    opacity: 0;
  }

  100% {
    right: 1.4rem;
    opacity: 1;
  }
}

.topbar {
  display: none;
  background: $main-color;
  padding: .5rem;
  a {
    color: #ffffff;
    white-space: nowrap;
    &:hover {
      text-decoration: none;
    }
  }
  ul {
    &.socials {
      li {
        border: none;
        padding-right: .5rem;
      }
    }
    display: -webkit-box;
    display: flex;
    list-style: none;
    padding-left: 0;
    margin: 0;
    li {
      margin-right: 1rem;
      border-right: 1px solid #fff;
      padding-right: 1rem;
      &:last-child {
        border: none;
      }
    }

  }
}

.box-share {
  padding: 1.45rem 0;
  border-top: 1px solid #ececec;
  margin-top: 2rem;
  text-align: center;

  span {
    display: block;
    padding-bottom: 6px;
    font-size: .875em;
    color: #4c4c4c;
  }

  button {
    background: transparent;
    border: 1px solid $main-color;
    margin-right: 8px;
    width: 34px;
    border-radius: 50%;
    font-size: 1.4em;
    color: $main-color;
    margin-bottom: 8px;
    transition: .25s;

    &:hover {
      background: $main-color;
      color: #ffffff;
      border: 1px solid #ffffff;
    }
  }
}

.has-caret {
    &:after {
      margin-left: 6px;
      color: #000000;
      font-size: .75em;
      content: "\f0d7";
      font-family: FontAwesome;
    }
  }

.rd-navbar-fixed {
  .has-caret {
    &:after {
      display: none;
    }
  }
}

.swiper-container {
  z-index: 0;
}

img {
  max-width: 100%;
}

button:focus, input:focus {
  outline: none;
}

.breadcrumb {
  background: #fbfbfb;
  border-radius: 3px;
  padding: 1rem;

  a {
    color: #34434a;

    &:hover {
      color: #000;
    }
  }
}

.fancy-video {
  position: relative;
  display: block;

  &:before {
    content: "";
    background: rgba(0, 0, 0, .25);
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
  }

  &:after {
    content: "\f144";
    font-family: FontAwesome;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: rgba(255, 255, 255, .75);
    text-align: center;
    font-size: 6rem;
    transition: .25s;
  }

  &:hover {
    &:after {
      color: #ffffff;
      transform: translateY(-52%);
    }
  }
}

.text-center {
  text-align: center;
}

/* sidebar */
.sidebar {
  z-index: 9;
  overflow-y: auto;

  &.sticky {
    overflow-y: inherit;
  }

  legend {
    font-size: 18px;
    font-weight: 600;
    border-bottom: 4px solid #dcdcdc;
    padding-bottom: 1rem;
  }

  .treeview {
    a span:before {
      content: "\f196";
      font-family: FontAwesome;
      padding-right: 6px;
      color: $main-color;
    }

    &.active {
      a span:before {
        content: "\f147";
        font-family: FontAwesome;
        padding-right: 6px;
        color: $main-color;
      }
    }
  }

  .sidebar-menu>.treeview>.treeview-menu {
    border-left: 1px solid #f5f5f5;
    background: #fbfbfb;
  }

  .sidebar-menu {

    .treeview {
      .menu-open {
        a {
          span {
            &:before {
              content: "\f196";
            }
          }
        }

        .treeview.active {
          a {
            span {
              &:before {
                content: "\f147";
              }
            }
          }
        }
      }
    }

    list-style: none;
    margin: 2px 0 3rem 0;
    padding: 0;

    &.sticky {
      position: sticky;
      top: 70px;
    }
  }

  .sidebar-menu>li {
    &:not(.header) {
      border-bottom: 1px solid #f3f3f3;
    }

    position: relative;
    margin: 0;
    padding: 0;
  }

  .sidebar-menu>li>a {
    &.active {
      border-left: 3px solid $main-color;
      background: #f9f9f9;
      text-transform: uppercase;
    }

    padding: 8px 5px 8px 15px;
    display: block;
    border-left: 3px solid transparent;
    color: $gray-700;
  }

  .sidebar-menu>li>a>.fa {
    width: 20px;
  }

  .sidebar-menu>li:hover>a:not(.active), .sidebar-menu>li.active>a {
    color: $gray-700;
    border-left-color: $gray-200;
  }

  .sidebar-menu>li>.treeview-menu {
    margin: 0;
  }

  .sidebar-menu>li .label,
  .sidebar-menu>li .badge {
    margin-top: 3px;
    margin-right: 5px;
  }

  .sidebar-menu li.header {
    font-family: 'DIN Pro Medium';
    padding: .60rem;
    color: #000000;
    border-bottom: 1px solid #e9ecef;
  }

  .sidebar-menu li>a>.fa-angle-left {
    width: auto;
    height: auto;
    padding: 0;
    margin-right: 10px;
    margin-top: 3px;
  }

  .sidebar-menu li.active>a>.fa-angle-left {
    transform: rotate(-90deg);
  }

  .sidebar-menu li.active>.treeview-menu {
    display: block;
  }

  .sidebar-menu a {
    color: #b8c7ce;
    text-decoration: none;
  }

  .sidebar-menu .treeview-menu {
    display: none;
    list-style: none;
    margin: 0;
    padding-left: 0;
  }

  .sidebar-menu .treeview-menu .treeview-menu {
    padding-left: 20px;
  }

  .sidebar-menu .treeview-menu>li {
    margin: 0;
  }

  .sidebar-menu .treeview-menu>li>a {
    &:before {
      content: "\f0da";
      font-family: FontAwesome;
      margin-right: 6px;
    }

    padding: 8px 5px 8px 15px;
    display: block;
    color: $gray-600;
  }

  .sidebar-menu .treeview-menu>li>a>.fa {
    width: 20px;
  }

  .sidebar-menu .treeview-menu>li>a>.fa-angle-left,
  .sidebar-menu .treeview-menu>li>a>.fa-angle-down {
    width: auto;
  }

  .sidebar-menu .treeview-menu>li.active>a, .sidebar-menu .treeview-menu>li>a:hover {
    color: #495057;
  }
}

.breadcrumb-item+.breadcrumb-item::before {
  content: "\f054";
  font-family: FontAwesome;
  font-size: 10px;
}

.sidebar-on {
  &:after {
    content: "";
    background: rgba(0, 0, 0, .25);
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: 1;
  }
}

.button {
  border-radius: 2px;
  padding: 6px 12px 6px 12px;
  font-size: .875em;
  transition: .25s linear;
  margin-right: 2px;
  margin-left: 2px;

  &--primary {
    background: $main-color;
    color: #ffffff;
    padding: .65rem 1rem;
    border-radius: 4px;
    border: none;
    &:hover {
      text-decoration: none;
      color: #ffffff;
      background: darken($main-color, 4%);
    }
  }

  &--dark {
    @extend .button;
    background: #000;
    border: 1px solid #333;
    color: #fff;

    &:hover {
      background: #1f1f1f;
    }
  }

  &--light {
    @extend .button;
    background-color: #f5f6f7;
    border: 1px solid #dadde1;
    color: $black;

    &:hover {
      background: #e0e0e0;
    }
  }
}

// search
.search {
  input {
    height: 40px;
    min-width: 240px;
  }
}

// grid
.grid {
  margin-top: 1rem;
  .grid-item {
    border-radius: 4px;
    padding: 1rem;
    border: 1px solid #e8e8e8;
    background: #fbfbfb;
    margin-bottom: 2rem;
    a {
      color: #333333;
      &:hover {
        text-decoration: none;
      }
      p {
        padding: 1rem .5rem;
        text-align: center;
        font-size: 1.125em;
        font-family: 'DIN Pro Medium';
        margin-bottom: 0;
      }
    }
  }
}

//table
.table td, .table th {
  padding: .5rem;
  vertical-align: top;
  border-top: 1px solid #efefef;
  font-size: 13px;
}

//alert
.alert {
  padding: 1rem;
  border-radius: 4px;

  &--info {
    @extend .alert;
    background: #e9f5ff;
    color: #354552;
    border: 1px solid #c1cad2;

    &:before {
      content: "\f05a";
      font-family: FontAwesome;
      padding: .5rem;
    }
  }

  &--danger {
    @extend .alert;
    background: #af3418;
    color: #ffd2c8;
    border: 1px solid #882c17;

    &:before {
      content: "\f00d";
      font-family: FontAwesome;
      padding: .5rem;
    }
  }

  &--light {
    @extend .alert;
    background: #fdfdfd;
    color: #354552;
    border: 1px solid #f3f3f3;
  }
}

.custom-control-label {
  &:before, &:after {
    top: 2px;
  }
}

.custom-control-input:checked~.custom-control-label::before {
  border-color: $main-color;
  background-color: $main-color;
}

label {
  color: #424242;
  margin-bottom: 4px;
  font-size: .875em;
  font-family: 'DIN Pro Medium';
  &.form-check-label {
    font-size: 1em;
    font-family: 'DIN Pro';
  }
}

.rd-navbar-fixed {
  .rd-navbar-collapse-toggle {
    &.sidemenu {
      right: 1.5rem;
      z-index: 15;
    }
  }
}

b, strong {
  font-family: 'DIN Pro Medium';
  -webkit-text-stroke-width: 0;
}

hr {
  border-top: 1px solid #fff;
}

.pagination-button {
  position: relative;
  float: left;
  padding: 6px 12px;
  line-height: 1.42857143;
  text-decoration: none;
  color: $gray-700;
  background-color: #fff;
  border: 1px solid #ddd;
  margin-left: -1px;
}

.pagination {
  li {
    &.current {
      span {
        background: $main-color;
        color: #ffffff;
      }
    }

    a, span {
      @extend .pagination-button;
    }

    a:hover {
      background: #f3f3f3;
    }
  }
}

.pagestr {
  @extend .pagination-button;
}

iframe {
  max-width: 100% !important;
}

.simple-popup-content {
  padding: 10px 40px;
  overflow: hidden;
}

.preloader {
  opacity: .25;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, .25);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  transition: opacity 0.3s linear;
}

.flex-items {
  display: flex;
  .flex-item {

  }
}

section {
  margin: 2rem 0;
  &:first-child {
    margin: 0 0 2rem 0;
  }
}

.swiper-container {
  overflow: hidden;
}

.card {
  &.common {
    border: none;
    &.borderless {
      .card-header {
        border: none;
        background: #fbfbfb;
      }
    }
    .card-header {
      background: transparent;
      font-family: 'DIN Pro Medium';
      color: #002c42;
      font-size: 1.25em;
      position: relative;
      &.square-pink {
        &:before {
          content: "";
          background: #f55753;
          width: 4px;
          position: absolute;
          left: 0px;
          top: 8px;
          bottom: 8px;
        }
      }

      &.square-purple {
        &:before {
          content: "";
          background: #6d5cae;
          width: 4px;
          position: absolute;
          left: 0px;
          top: 8px;
          bottom: 8px;
        }
      }

      &.square-blue {
        &:before {
          content: "";
          background: $main-color;
          width: 4px;
          position: absolute;
          left: 0px;
          top: 8px;
          bottom: 8px;
        }
      }

      &.greenline {
        &:before{
          content: "";
          height: 100%;
          border-left: 2px solid #0db2ac;
          margin-right: 1rem;
          }
        }

    }
    .card-footer {
      font-size: .975em;
      background: transparent;
      a {
        text-decoration: none;
        &:hover {
          color: #002c42;
        }
      }
    }
    ul:not(.pagination) {
      padding-left: 0;
      list-style: none;
      margin-bottom: 0;

      li {
        margin-bottom: .5rem;
        a {
          color: #2f2f2f;
          text-decoration: none;
          &:hover {
            color: $black;
          }
          &:before {
            content: "\f192";
            font-family: FontAwesome;
            margin-right: 4px;
            color: $main-color;
          }
        }
      }

      &.pink {
        li {
          a {
            &:before {
              color: #f55753;
            }
          }
        }
      }

    }


    }

}


.page-item.active .page-link {
  background-color: $main-color;
  border-color: $main-color;
}

.page-link:hover {
  color: $main-color;
}

a {
  color: $main-color;
}

.p-margin-half {
  p {
    margin-bottom: .5rem;
  }
}



#medical_centers, #featured_centers {
  overflow: hidden;
    margin-top: .7rem;
}

.medical-center {
  span {
    display: block;
    margin-top: .5rem;
    text-align: center;
    font-family: 'DIN Pro Medium';
  }
}

.info-bank {
  display: -webkit-box;
  display: flex;
  li {
    width: 100%;
  }
}

@media(max-width: 641px) {
  .info-bank {
    display: block;
    li {
      display: inline-block;
      width: 49%;
    }
  }
}

.video-list {
  p {
    @include ellips(6);
  }
}

footer {
  background: #f8f9fa;
  padding: 2rem 0;
  color: #000000;
  a {
    color: #000000;
    &:hover {
      text-decoration: none;
      color: $main-color;
    }
  }
  span {
    font-size: 1.25em;
    font-family: 'DIN Pro Medium';
    padding-bottom: .5rem;
    border-bottom: 1px solid #dedede;
    display: block;
    margin-bottom: .5rem;
  }
  ul {
    padding:0;
    list-style: none;
    li {
      margin-bottom: .5rem;
    }
  }
}


.copyright {
  margin: 0;
  padding: 1rem;
  text-align: center;
  font-size: .825em;
  background: #333333;
  color: #dedede;
}

.submenu-list {
  display: block;
  overflow: hidden;
  .submenu-list-item {
      width: 100%;
      position: relative;
      height: 60px;
      margin-bottom: 1rem;
      a {
        padding: 1rem;
        height: 100%;
      }
      &.default {
        background: $main-color;
        &:hover {
          .icon-wrapper {
            background: darken(#002c42, 5%);
          }
        }
        a {
          color: #ffffff;
        }
        .icon-wrapper {
          background: #002c42;
        }
      }
      &.alternate {
        background: #4e4e4e;
        &:hover {
          .icon-wrapper {
            background: darken(#ebb725, 5%);
          }
        }
        a {
          color: #ffffff;
        }
        .icon-wrapper {
          background: #ebb725;
        }
      }
      .icon-wrapper {
        transition: .25s;
        font-size: 2em;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        width: 60px;
        text-align: center;
        i {
          transform: translateY(10px);
        }
      }

      .text-wrapper {
        margin-left: 54px;
        transform: translateY(5px);
      }
      &:after {
        content: "\f0da";
        font-family: FontAwesome;
        position: absolute;
        font-size: 1.25em;
        right: 1rem;
        top: 50%;
        transform: translateY(-50%);
        color: #ffffff;
      }
    }
    a {
      display: -webkit-box;
      display: flex;
      &:hover {
        text-decoration: none;
      }
    }

  }


  .rd-navbar-static {
    .navbar-search {
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      .form-group {
        position: relative;
        .btn-search {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          padding: 6px 14px;
          border: none;
        }
      }
      input {
        width: 360px!important;
      }

    }
  }


  //Sidebar

  .sidebar {
    .sidebar-buttons {
      a {
        padding: 1rem 0 1rem 6rem;
        display: block;
        margin-bottom: 1rem;
        position: relative;
        font-size: 1.25em;
        transition: .25s;
        color: #ffffff;
        font-family: 'DIN Pro Medium';
        &:hover {
          text-decoration: none;
        }
        &.phone {
          background: linear-gradient(45deg, #729cb5, #53cab6, #26df9c);
          &:hover {
            background-position: -10px;
          }
          &:before {
            content: "\f098";
            font-family: FontAwesome;
            position: absolute;
            left: 2.5rem;
            top: 50%;
            transform: translateY(-50%);
            font-size: 1.75em;
          }
        }
        &.staff {
          background: linear-gradient(45deg, #7675b9, #578fcc, #2eaae0);
          &:hover {
            background-position: -10px;
          }
          &:before {
            content: "\f0f0";
            font-family: FontAwesome;
            position: absolute;
            left: 2.5rem;
            top: 50%;
            transform: translateY(-50%);
            font-size: 1.75em;
          }
        }

      }
    }
    .card {
      .card-header {
        padding: 1rem 0;
      }
      .card-body {
        padding: 1rem 0;
      }
    }
  }

  .rd-navbar-fixed {
    .navbar-search {
      position: fixed;
      right: 104px;
      top: 22px;
      z-index: 99;
      input {
        display: none;
        position: fixed;
        width: 172px;
        left: 1rem;
        height: 44px;
        top: .9rem;
        transform: translateX(-100px);
        transition: .25s;
      }
      .btn-search {
        border: none;
        background: transparent;
        font-size: 1.25em;
      }
    }
  }

.partners {
  display: none;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  li {
    padding: 0 .5rem;
  }
  img {
    max-height: 65px;
  }
}

// academic list
.academic-list {
  .academic-list-item {
    position: relative;
    padding: 0 0 1rem 0;
    background: #f9f9f9;
    border-radius: 4px;
    margin-bottom: 2rem;
    &:after {
      content: "";
      width: 16px;
      height: 16px;
      position: absolute;
      bottom: .5rem;
      left: .5rem;
      border-left: 2px solid #0db2ac;
      border-bottom: 2px solid #0db2ac;
    }
    img {
      margin-bottom: .5rem;
    }
      p {
        margin-bottom: 0;
        font-family: 'DIN Pro Medium';
        text-align: center;
      }
      span {
        display: block;
        text-align: center;
      }
  }
}


// filter

.filter {
  margin-bottom: 2rem;
  padding: 1rem;
  background: #fbfbfb;
      border: 1px solid #f5f5f5;
}


// contact
.contact-card {
  padding: 1rem!important;
  border: 1px solid #f3f3f3;
  border-radius: 4px;
  background: #fdfdfd;
}

.form-control:focus {
  border: 1px solid #ced4da;
  box-shadow: none;

}

.guide-buttons {
  margin-top: 2.5rem;
  a {
    color: #333333;
    &:hover {
      text-decoration: none;
    }
  }
  .guide-button {
    display: inline-block;
    padding: 1rem;
    margin: .5rem;
    width: 100%;
    background: #f5f5f5;
    border-radius: 6px;
    text-align: center;

    i {
      font-size: 2em;
      color: $main-color;
      margin-bottom: .5rem;
    }
    span {
      display: block;
      font-family: 'DIN Pro Medium';
      white-space: nowrap;
    }
  }
}
